import React, { useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { AddDestinationDocument, AddDestinationMutation, AddDestinationMutationVariables, CreateDraftOrderDocument, CreateDraftOrderMutation, CreateDraftOrderMutationVariables } from '../graphql/__generated__'
import DesignChooser from '../components/DesignChooser'
import { gtagAddToCart } from '../utils/GTag'

export default function CloneDesignToNewOrder() {
  const navigate = useNavigate()
  const { cloneDesignId } = useParams()

  const [createDraftOrder] = useMutation<
    CreateDraftOrderMutation,
    CreateDraftOrderMutationVariables
  >(CreateDraftOrderDocument)

  const [addDestination] = useMutation<
    AddDestinationMutation,
    AddDestinationMutationVariables
  >(AddDestinationDocument)

  useEffect(() => {
    if (!cloneDesignId) {
      return
    }

    createDraftOrder({
      variables: {
        order: {
          cloneDesignId,
        },
      },
      onCompleted: async ({ createDraftOrder : { order: { id: orderId, designs } }}) => {
        gtagAddToCart(orderId)

        const designId = designs?.[0].design?.id

        let destinationId: string | undefined
        if (designId) {
          await addDestination({
            variables: {
              orderId,
              designId,
            },
            onCompleted: ({ addDestination: { designs } }) => {
              destinationId = designs?.find(d => d.design?.id === designId)?.destinations?.[0].id
            }
          })
        }

        const maybeDesign = designId ? `/design/${ designId }` : ""
        const maybeDestination = destinationId ? `/destination/${ destinationId }` : ""
        navigate(`/order/${ orderId }${ maybeDesign }${ maybeDestination }/display`, { replace: true })
      },
    })
  }, [cloneDesignId])

  if (cloneDesignId) {
    // render loading until mutations finish and redirected to new order
    return <div className="text-center my-8">
      <CircularProgress />
    </div>
  }

  return <div className="bg-gray-50 rounded mb-4 p-4">
    <h2 className="text-xl mb-2">Reorder a Past Book</h2>
    <p className="mb-6 text-sm">Click on a book below to start a new order based on the shown design.</p>
    <DesignChooser onChange={ designId => navigate(`/design/${designId}/clone`) } />
  </div>
}
