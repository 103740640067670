import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import DestComponentEditor from '../components/DestComponentEditor'
import useSteps, { BuilderFlow, GiftFlow, GiftDisplayStep, DisplayStep, removeBoxStepIfSevenInch } from '../utils/Steps'
import { useStepContext } from '../utils/StepContext'
import { CircularProgress } from '@mui/material'
import { useRedirectWithDesignDestination } from '../utils/RedirectWithDesignDestination'
import { useAuthenticatedQuery } from '../apollo-client'
import { BookType, Maybe, OrderDocument, OrderQuery } from '../graphql/__generated__'
import DisplayForm, { DisplayFormOptions } from '../components/DisplayForm'
import { destinationDisplaySize, DisplaySize, findDestination } from '../utils/Destination'

interface DisplayEditorProps {
  isGift?: boolean
}

export default function DisplayEditor({ isGift }: DisplayEditorProps) {
  const { orderId, designId, destinationId } = useParams()

  const step = (() => {
    if (isGift) {
      return GiftDisplayStep
    }
    return DisplayStep
  })()

  const { navigateNext, navigatePrev, currentPath, currentStep, hasReturn } = useSteps()

  const { loading } = useRedirectWithDesignDestination({
    orderId, designId, destinationId,
    skip: currentStep !== step,
    currentPath,
  })

  const {
    data: { orderStatus: order } = {},
  } = useAuthenticatedQuery<OrderQuery>(OrderDocument, {
    variables: {
      orderId,
    },
  })

  const destination = order && destinationId ? findDestination(order, destinationId) : undefined
  const bookType = destination?.bookType

  const { setStepState } = useStepContext()
  useEffect(() => {
    setStepState({
      flow: removeBoxStepIfSevenInch(isGift ? GiftFlow : BuilderFlow, bookType),
      step,
      args: { orderId, destinationId, designId },
    })
  }, [isGift, bookType, orderId, destinationId, designId])

  const [size, setSize] = useState<Maybe<DisplaySize>>(destinationDisplaySize(destination))

  const { search } = useLocation()
  const showWarningAboutBox = bookType === BookType.SevenInch && !!(new URLSearchParams(search).get('displayBox'))

  if (!orderId) {
    return <>Missing order id</>
  }

  if (loading) {
    return <div className="flex my-4 justify-center">
      <CircularProgress />
    </div>
  }

  return <>
    <DestComponentEditor<DisplayFormOptions>
      form={ DisplayForm }
      formOptions={{ setSize }}
      text={{
        pageTitle: 'Display',
        pageDescription: <>
        <div className="bg-gray-50 p-4 space-y-2">
          <p>Before designing your book, choose a display size. This selection can always be changed later.</p>
        </div>
          { showWarningAboutBox && (
            <div className="bg-yellow-100 p-4 space-y-2 mt-2">
              <p>To use a gift box, please change to a 5" display.</p>
            </div>
          ) }
        </>,
        componentName: 'Display',
      }}
      orderId={ orderId }
      destinationId={ destinationId }
      designId={ designId }
      disableSave={!size}
      hasReturn={ hasReturn }
      onDone={ () => {
        navigateNext({})
      }}
      onBack={ () => {
        hasReturn ? navigateNext({}) : navigatePrev({})
      }}
    />
  </>
}
