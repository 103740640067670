import React, { useMemo, useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useAuthenticatedQuery, useAuthentication } from '../apollo-client'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { ArrowPathIcon, CameraIcon, GiftIcon, PencilSquareIcon, ArrowDownOnSquareIcon } from '@heroicons/react/24/outline'

import { AddDestinationDocument, AddDestinationMutation, CreateDraftOrderDocument, CreateDraftOrderMutation, OrdersDocument,
         OrdersQuery, OrdersQueryVariables, OrderState, UpdateOrderMetadataDocument, UpdateOrderMetadataMutation, OrderFilter } from '../graphql/__generated__'
import ProgressOverlay from '../components/ProgressOverlay'
import CustomerBalanceSummary from '../components/CustomerBalanceSummary'
import { useStepContext } from '../utils/StepContext'
import { CircularProgress } from '@mui/material'
import LoginModal from '../components/LoginModal'
import CreditsModal from '../components/CreditsModal'
import { gtagAddToCart } from '../utils/GTag'
import BigOptions from '../components/BigOptions'
import type { BigOption } from '../components/BigOptions'

declare let gtag: Function

export default function Onboarding() {
  // TODO Query for an existing design
  // Show the design options if multiple exist
  const navigate = useNavigate()
  const { codeVendor, presetCodes } = useParams()

  // Reset to hide steps in nav
  const { setStepState } = useStepContext()
  setStepState(undefined)

  const { customerLoading, customerError, customerData: me, customerId } = useAuthentication()

  // If a customer is coming in from QVC, we collect their prepurchase codes in the login modal,
  // but if somehow they're already logged in, we show the credits modal to collect the codes.
  const [ showCreditsModal, setShowCreditsModal ] = useState<boolean>(!!me && !!codeVendor)
  useEffect(() => {
    setShowCreditsModal(!!me && !!codeVendor)
  }, [me, codeVendor])

  const closeCreditsModal = () => {
    setShowCreditsModal(false)

    navigate('/', { replace: true })
  }

  const {
    data: { customerOrders: orders } = {},
    loading: orderLoading,
    error: orderError,
  } = useAuthenticatedQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, {
    skip: !customerId,
    variables: {
      customerId: customerId ?? '',
      drafts: OrderFilter.All,
    },
  })

  const { draftOrders, completedOrdersWithDesign } = useMemo(() => {
    const result = {
      draftOrders: [] as NonNullable<OrdersQuery["customerOrders"]>,
      completedOrdersWithDesign: [] as NonNullable<OrdersQuery["customerOrders"]>,
    }

    return orders?.reduce((prev, order) => {
      switch (order.state) {
        case OrderState.Draft:
          prev.draftOrders.push(order)
          break
        case OrderState.Paid:
        case OrderState.Complete:
          if (order.designs?.find(d => !!d.design?.media?.length)) {
            prev.completedOrdersWithDesign.push(order)
          }
          break
      }
      return prev
    }, result) ?? result
  }, [orders])

  const [
    createDraftOrder,
    { loading: createLoading, error: createError },
  ] = useMutation<CreateDraftOrderMutation>(CreateDraftOrderDocument, {
    variables: {
      order: {},
    },
  })

  const [
    updateOrderMetadata,
    { loading: updatingMetadataLoading, error: updateMetadataError },
  ] = useMutation<UpdateOrderMetadataMutation>(UpdateOrderMetadataDocument)

  const [
    addDestination,
    { loading: addDestinationLoading, error: addDestinationError },
  ] = useMutation<AddDestinationMutation>(AddDestinationDocument)

  const error = customerError || orderError || createError || updateMetadataError || addDestinationError

  function resumeDraftOrder() {
    if (!draftOrders) {
      return
    }

    if (draftOrders.length === 1) {
      const order = draftOrders[0]
      const step = order.isEGift ? 'email' : 'display'
      let prefix = ''
      if (order.isEGift) {
        prefix = '/e-gift'
      } else if(order.isGift) {
        prefix = '/gift'
      }
      navigate(`${ prefix }/order/${ order.id }/${ step }`)
    } else {
      navigate('/orders/draft')
    }
  }

  function buildNewBook() {
    createDraftOrder({
      onCompleted: async ({ createDraftOrder : { order: { id: orderId, designs } }}) => {
        gtagAddToCart(orderId)

        const designId = designs?.[0].design?.id

        let destinationId: string | undefined
        if (designId) {
          await addDestination({
            variables: {
              orderId,
              designId,
            },
            onCompleted: ({ addDestination: { designs } }) => {
              destinationId = designs?.find(d => d.design?.id === designId)?.destinations?.[0].id
            }
          })
        }

        const maybeDesign = designId ? `/design/${ designId }` : ""
        const maybeDestination = destinationId ? `/destination/${ destinationId }` : ""
        navigate(`/order/${ orderId }${ maybeDesign }${ maybeDestination }/display`)
      }
    })
  }

  function sendGift() {
    createDraftOrder({
      onCompleted: async ({ createDraftOrder : { order: { id: orderId, designs } }}) => {
        gtagAddToCart(orderId)

        const designId = designs?.[0].design?.id

        const mutations: Promise<any>[] = [
          updateOrderMetadata({
            variables: {
              orderId: orderId,
              metadataJSON: JSON.stringify({ gift: true })
            },
          }),
        ]

        // We don't need the destinationId, but we still create the blank destination;
        // Display/Cover/Address/Email/Message steps redirect w/destinationId if only 1.
        if (designId) {
          mutations.push(addDestination({
            variables: {
              orderId,
              designId,
            },
          }))
        }

        await Promise.all(mutations)
        navigate(`/gift/order/${ orderId }/select`)
      }
    })
  }

  let bigOptions: Array<BigOption> = []

  bigOptions.push({
    title: "Build Your Book",
    subtitle: "Select a book cover (or design your own), add your media and music, checkout.",
    icon: CameraIcon,
    active: !draftOrders.length,
    onClick: buildNewBook,
  })

  if (draftOrders.length) {
    bigOptions.push({
      title: "Resume Building Your Book",
      subtitle: `Finish ordering one of your ${ draftOrders.length >= 10 ? '10+' : draftOrders.length } incomplete order${ draftOrders.length > 1 ? 's' : '' }.`,
      icon: PencilSquareIcon,
      onClick: resumeDraftOrder,
    })
  }

  if (!!completedOrdersWithDesign?.length) {
    bigOptions.push({
      title: "Reorder a Past Book",
      subtitle: "Start a new order for a book you purchased previously.",
      active: !draftOrders.length,
      icon: ArrowPathIcon,
      onClick: () => navigate("/designs/clone"),
    })
  }

  bigOptions.push({
    title: "Order a Blank Book or Gift Card",
    subtitle: "Buy a blank book for you or your recipient to load, or a physical or electronic gift card to send.",
    icon: GiftIcon,
    active: !draftOrders.length,
    onClick: sendGift,
  })

  bigOptions.push({
    title: "Build and Load Your Blank Book",
    subtitle: "Load a blank book with photos, videos and music.",
    icon: ArrowDownOnSquareIcon,
    onClick: () => navigate("/local"),
  })



  return <>
    <LoginModal codeVendor={ codeVendor } presetCodes={ presetCodes } />
    <CreditsModal opened={ showCreditsModal } close={ closeCreditsModal } presetCodes={ presetCodes } />

    { (customerLoading || orderLoading)
    ? <div className="text-center mb-8">
      <CircularProgress />
    </div> : <>
      { (createLoading || updatingMetadataLoading || addDestinationLoading) && <ProgressOverlay /> }

      <h1 className="my-2 md:mt-0 text-xl md:text-2xl text-center md:text-left font-extrabold text-gray-900">Video Book Builder</h1>

      { !!draftOrders && error &&
        <p className="p-4 my-2 bg-red-200">
          There was an error creating your book, please try again.
        </p>
      }

      <BigOptions options={ bigOptions } />
    </> }

    { me ?
      <div className="mb-4 p-4 rounded bg-gray-50">
        <div className="mb-6">
          <h2 className="text-lg">Your Balance</h2>
          <span className="text-sm">These credits will automatically be applied against the books you build.</span>
        </div>
        <CustomerBalanceSummary balance={ me.balance } onAdd={ () => { setShowCreditsModal(true) } } />
      </div>
      : <></>
    }

    <p className="mb-4 md:mb-0 mt-8 text-center block">Need help? You can always
      <Link to="/help"
         className="focus:outline-pink-300 text-pink-500 ml-1"
      >contact us</Link>
    .</p>
  </>
}
