import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { BuilderFlow, removeBoxStepIfSevenInch, ReviewStep } from '../utils/Steps'
import { useStepContext } from '../utils/StepContext'
import NewDesignOptions from '../components/NewDesignOptions'
import { useAuthenticatedQuery } from '../apollo-client'
import { BookType, OrderDocument, OrderQuery, OrderQueryVariables } from '../graphql/__generated__'

export default function NewDesignSelection() {
    const { orderId } = useParams()
    
    const {
        data: { orderStatus: order } = {},
    } = useAuthenticatedQuery<OrderQuery, OrderQueryVariables>(OrderDocument, {
        skip: !orderId,
        variables: {
            orderId: orderId ?? '',
        },
    })
      
    // only care if all are 7", then hide Box step
    const bookTypes = new Set<BookType>()
    order?.designs?.forEach(design => design?.destinations?.forEach(dest => {
        if (dest.bookType) {
          bookTypes.add(dest.bookType)
        } else {
          bookTypes.add(BookType.FiveInch)
        }
    }))
    const bookType = bookTypes.size === 1 ? Array.from(bookTypes)[0] : null

    const { setStepState } = useStepContext()
    useEffect(() => {
        setStepState({
            flow: removeBoxStepIfSevenInch(BuilderFlow, bookType),
            step: ReviewStep,
            args: { orderId },
        })
    }, [orderId, bookType])

    // an orderId is always expected here, since this is for the BuilderFlow
    return orderId ? <NewDesignOptions orderId={ orderId } /> : <></>
}