import React, { useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'

import AddressForm from '../components/AddressForm'
import DestComponentEditor from '../components/DestComponentEditor'
import { useStepContext } from '../utils/StepContext'
import useSteps, { GiftFlow, BuilderFlow, GiftAddressStep, AddressStep, removeBoxStepIfSevenInch } from '../utils/Steps'
import { CircularProgress } from '@mui/material'
import { useRedirectWithDesignDestination } from '../utils/RedirectWithDesignDestination'
import { useAuthenticatedQuery } from '../apollo-client'
import { OrderDocument, OrderQuery } from '../graphql/__generated__'
import { findDestination } from '../utils/Destination'

interface AddressEditorProps {
  isGift?: boolean
}

export default function AddressEditor({ isGift }: AddressEditorProps) {
  const { orderId, destinationId, designId } = useParams()

  const step = isGift ? GiftAddressStep : AddressStep

  const { navigateNext, navigatePrev, currentPath, currentStep, hasReturn } = useSteps()

  const { loading } = useRedirectWithDesignDestination({
    orderId, designId, destinationId,
    skip: currentStep !== step,
    currentPath,
  })
  
  const {
    data: { orderStatus: order } = {},
  } = useAuthenticatedQuery<OrderQuery>(OrderDocument, {
    variables: {
      orderId,
    },
  })

  const destination = order && destinationId ? findDestination(order, destinationId) : undefined
  const bookType = destination?.bookType

  const { setStepState } = useStepContext()
  useEffect(() => {
    setStepState({
      flow: removeBoxStepIfSevenInch(isGift ? GiftFlow : BuilderFlow, bookType),
      step,
      args: { orderId, destinationId, designId },
    })
  }, [isGift, bookType, orderId, destinationId, designId])

  if (!orderId) {
    return <>Missing order id</>
  }

  if (loading) {
    return <div className="flex my-4 justify-center">
      <CircularProgress />
    </div>
  }

  return <div>
    <DestComponentEditor
      form={ AddressForm }
      text={ {
        pageTitle: 'Shipping Address',
        componentName: 'Address',
        addMoreHint: 'Want to send your book to multiple people?',
      } }
      orderId={ orderId }
      destinationId={ destinationId }
      designId={ designId }
      hasReturn={ hasReturn }
      onDone={ () => {
        navigateNext({})
      }}
      onBack={ () => {
        hasReturn ? navigateNext({}) : navigatePrev({})
      }}
    />
  </div>
}
