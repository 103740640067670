import React from 'react'
import { useFormContext } from 'react-hook-form'

import { formatPrice } from '../utils/Price'
import Link from './Link'

export type GiftBoxFormFields = {
  hasGiftBox: boolean
}

type Props = {
  disabledReason?: string
}

export default function GiftBoxFormInput({ disabledReason }: Props) {
  const { register } = useFormContext<GiftBoxFormFields>();

  return  (
    <div className="relative">
      <div className="md:grid grid-cols-4 gap-2">
        <div className="col-span-3 self-center">
          <p>
            <label className="flex">
              <input
                {...register("hasGiftBox")}
                type="checkbox"
                disabled={ !!disabledReason }
                className="
                  flex-shrink-0
                  cursor-pointer
                  h-8
                  w-8
                  mt-[3px]
                  border border-gray-300
                  rounded
                  checked:bg-black
                  checked:border-transparent
                  focus:outline-none
                "
              />
              <span className="text-lg ml-4"
                >Use a Gift Box [{ formatPrice(1200) }] <span className="text-base bg-gray-100 text-gray-900 font-bold px-2 py-1 rounded-full">Recommended</span>
              </span>
            </label>
          </p>
          <p className="mt-1 ml-2 pl-10 opacity-50">Your video book will be packed in our custom-designed gift box and secured with a beautiful bow.</p>
        </div>
        <div
          className="md:flex-shrink-0 mt-4 md:mt-0 mr-2">
          <div
            className="block w-full h-0 relative bg-center bg-no-repeat bg-cover rounded shadow"
            style={{
              backgroundImage: "url('https://cdn.shopify.com/s/files/1/0457/5667/8307/products/SeindHeirloomFlatlayAbsoluteFinals_48copy.png?v=1637351758')",
              paddingTop: "68%",
            }}
          >
          </div>
        </div>
      </div>
      { disabledReason == 'cover' && (
        <div className="absolute inset-0 backdrop-blur flex justify-center items-center">
          <div className="p-2 bg-white bg-opacity-60 rounded">
            <p className="text-pink-500 font-bold">Unfortunately gift boxes are not available with our horizontal-only covers.</p>
            <p className="text-pink-500">Please <Link href="cover" className="underline"><>select a different cover</></Link> to use a gift box.</p>
          </div>
        </div>
      ) }
      { disabledReason == 'sold-out' && (
        <div className="absolute inset-0 backdrop-blur flex justify-center items-center">
          <div className="p-2 bg-white bg-opacity-60 rounded">
            <p className="text-pink-500 font-bold">Unfortunately we are currently sold out of gift boxes! Please check back in a few weeks.</p>
          </div>
        </div>
      ) }
    </div>
  )
}
