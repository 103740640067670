import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'

import { useQueryParams } from '../utils/Query'
import OrderEdit from '../components/OrderEdit'
import OrderStatus from '../components/OrderStatus'
import FeedbackWidget from '../components/FeedbackWidget'
import { BookType, OrderDocument, OrderQuery, OrderQueryVariables } from '../graphql/__generated__'
import { BuilderFlow, GiftFlow, EGiftFlow, ReviewStep, ThanksStep, OrderStatusStep, GiftReviewStep, EGiftReviewStep, removeBoxStepIfSevenInch } from '../utils/Steps'
import { useStepContext } from '../utils/StepContext'
import { useAuthenticatedQuery } from '../apollo-client'
import { findDestination } from '../utils/Destination'

type OrderSummaryProps = {
  isEGift?: boolean
  isGift?: boolean
  showSteps?: boolean
}

export default function OrderSummary({ isEGift, isGift, showSteps }: OrderSummaryProps) {
  const params = useQueryParams()
  const thanks = params.get('thanks') === 'true'
  const edit = params.get('edit') === 'true'
  const { orderId } = useParams()

  const {
    data: { orderStatus: order, stock } = {},
    loading,
    error,
  } = useAuthenticatedQuery<OrderQuery, OrderQueryVariables>(OrderDocument, {
    skip: !orderId,
    variables: {
      orderId: orderId ?? '',
    },
    fetchPolicy: 'network-only',
  })
  
  // only care if all are 7", then hide Box step
  const bookTypes = new Set<BookType>()
  order?.designs?.forEach(design => design?.destinations?.forEach(dest => {
    if (dest.bookType) {
      bookTypes.add(dest.bookType)
    } else {
      bookTypes.add(BookType.FiveInch)
    }
  }))
  const bookType = bookTypes.size === 1 ? Array.from(bookTypes)[0] : null

  const { setStepState } = useStepContext()
  useEffect(() => {
    setStepState({
      flow: (() => {
        var flow = BuilderFlow
        if (isEGift) {
          flow = EGiftFlow
        } else if (isGift) {
          flow = GiftFlow
        }
        return removeBoxStepIfSevenInch(flow, bookType)
      })(),
      step: (() => {
        if (thanks) {
          return ThanksStep
        }
        if (showSteps === false) {
          return OrderStatusStep
        }
        if (isEGift) {
          return EGiftReviewStep
        }
        if (isGift) {
          return GiftReviewStep
        }
        return ReviewStep
      })(),
      args: { orderId },
    })
  }, [isEGift, isGift, bookType, thanks, showSteps, orderId])

  useEffect(() => {
    if (thanks) {
      // Show feedback widget
      // @ts-ignore TS doesn't like us messing with mysterious globals
      window._mfq && window._mfq.push(['activateFeedback', 'QTTkeHvopkC_WRsoObExyQ'])
    }
  }, [thanks])

  if (!orderId) {
    return <div>No order ID</div>
  }

  if (loading) {
    return <div className="flex justify-center"><CircularProgress /></div>
  }

  // The order state will automatically move to 'paid' when the
  // Stripe webhook completes. If this is marked as a thank you
  // page though, we know the user at least thinks they paid and
  // should show them the paid UI.
  const paid = order?.state !== 'draft' || thanks

  return <div className="md:max-w-[68rem]">
    <h1 className="text-xl mb-6">Your Order</h1>

    { error && <p className="p-2 m-4 bg-red-300">Error loading order.</p> }

    { (!paid || edit)
      ? <OrderEdit order={ order } stock={ stock } />
      : <OrderStatus order={ order } showThanks={ thanks } />
    }

    { thanks && <FeedbackWidget order={ order } /> }
  </div>
}
