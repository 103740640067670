import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import CoverForm, { CoverFormOptions } from '../components/CoverForm'
import DestComponentEditor from '../components/DestComponentEditor'
import useSteps, { BuilderFlow, GiftFlow, CoverStep, GiftCoverStep, removeBoxStepIfSevenInch } from '../utils/Steps'
import { useStepContext } from '../utils/StepContext'
import { CircularProgress } from '@mui/material'
import { useRedirectWithDesignDestination } from '../utils/RedirectWithDesignDestination'
import { useAuthenticatedQuery } from '../apollo-client'
import { BookType, OrderDocument, OrderQuery } from '../graphql/__generated__'
import { findDestination } from '../utils/Destination'

interface CoverEditorProps {
  isGift?: boolean
}

export default function CoverEditor({ isGift }: CoverEditorProps) {
  const { orderId, designId, destinationId } = useParams()

  const step = isGift ? GiftCoverStep : CoverStep

  const { hasReturn, navigateNext, navigatePrev, navigateTo, currentPath, currentStep } = useSteps()

  const { loading } = useRedirectWithDesignDestination({
    orderId, designId, destinationId,
    skip: currentStep !== step,
    currentPath,
  })

  const {
    data: { orderStatus: order } = {},
  } = useAuthenticatedQuery<OrderQuery>(OrderDocument, {
    variables: {
      orderId,
    },
  })

  const destination = order && destinationId ? findDestination(order, destinationId) : undefined
  const bookType = destination?.bookType

  const { setStepState } = useStepContext()
  useEffect(() => {
    setStepState({
      flow: removeBoxStepIfSevenInch(isGift ? GiftFlow : BuilderFlow, bookType),
      step,
      args: { orderId, destinationId, designId },
    })
  }, [isGift, bookType, orderId, destinationId, designId])

  if (!orderId) {
    return <div>No order ID</div>
  }

  if (loading) {
    return <div className="flex my-4 justify-center">
      <CircularProgress />
    </div>
  }

  return <div className="mb-4">
    <DestComponentEditor<CoverFormOptions>
      form={ CoverForm }
      text={ {
        pageTitle: 'Cover',
        pageDescription: <p className="text-gray-500">
          {
            destination?.bookType === BookType.SevenInch
              ? "Choose if you'd like to design a custom cover for your video book."
              : "Select the cover you'd like for your video book."
          }
          &nbsp;All books are set in hardcovers with a matte finish.
        </p>,
        componentName: 'Cover',
      } }
      orderId={ orderId }
      destinationId={ destinationId }
      designId={ designId }
      hasReturn={ hasReturn }
      onDone={ () => {
        navigateNext({})
      }}
      onBack={ () => {
        hasReturn ? navigateNext({}) : navigatePrev({})
      }}
      formOptions={{
        onCustomCoverEdit: currentStep && orderId && designId && destinationId ? (customCoverId => {
          navigateTo(currentStep, {
            orderId, designId, destinationId,
            coverId: customCoverId,
          }, {})
        }) : undefined,
        hasNonBlankCovers: destination?.bookType !== BookType.SevenInch,
      }}
    />
  </div>
}
