import React from 'react'
import { Link } from 'react-router-dom'

import { BookType, type DestinationFragment, type OrderFragment } from "../graphql/__generated__"
import AddressSummary from './AddressSummary'
import QuantityForm from './QuantityForm'
import ShippingOptionsForm from './ShippingOptionsForm'
import ShippingChoice from './ShippingChoice'
import CoverImage from './CoverImage'
import PricingSummary from './PricingSummary'
import GiftMessage from './GiftMessage'
import useSteps, { CoverStep, MessageStep, AddressStep, EGiftMessageStep, EGiftEmailStep, GiftCoverStep, GiftAddressStep, GiftMessageStep, DisplayStep, GiftDisplayStep } from '../utils/Steps'
import type { Step } from '../utils/Steps'
import { DestinationValidation } from '../utils/OrderValidation'
import Button from './Button'

type DestinationSummaryProps = {
  destination: DestinationFragment
  destinationValidation?: DestinationValidation
  designId: string
  order: OrderFragment
  allowEdit: boolean
}

export default function DestinationSummary({ destination, destinationValidation, designId, order, allowEdit }: DestinationSummaryProps) {
  const costs = order?.cost?.byDestination?.find(c => c.destinationId === destination.id)?.costs || null
  const { navigateTo } = useSteps()

  const navigate = (step: Step) => () => {
    navigateTo(step, {
      designId,
      destinationId: destination.id
    }, { returnHere: true })
  }

  let displayStep = DisplayStep
  let coverStep = CoverStep
  let addressStep = AddressStep
  let messageStep = MessageStep
  if (order.isEGift || destination.digital) {
    addressStep = EGiftEmailStep
    messageStep = EGiftMessageStep
  } else if (order.isGift) {
    displayStep = GiftDisplayStep
    coverStep = GiftCoverStep
    addressStep = GiftAddressStep
    messageStep = GiftMessageStep
  }

  const _allowEdit = allowEdit && !order.isReplacement

  const screenSize = destination.bookType === BookType.SevenInch ? '7"' :'5"'

  return <>
    { destinationValidation?.missingCover &&
      <div className="p-2 pl-4 mb-4 bg-red-100 flex rounded">
        <p className="flex-1 m-auto">This book is missing a cover.</p>
        <Button onClick={ navigate(coverStep) } type="secondary">
          Select Cover
        </Button>
      </div>
    }

    { destinationValidation?.missingAddress &&
      <div className="p-2 pl-4 mb-4 bg-red-100 flex rounded">
        <p className="flex-1 m-auto">This book is missing an { order.isEGift ? 'email' : 'address' }.</p>
        <Button onClick={ navigate(addressStep) } type="secondary">
          Add { order.isEGift ? 'Email' : 'Address' }
        </Button>
      </div>
    }

    <div className="grid grid-cols-12 gap-2">
      <div className="col-span-12 md:col-span-3 mb-4 md:mb-0">
        { (!order.isEGift && !destination.digital) && <div className="mb-4">
          <h3 className="font-bold inline-block mr-2">Display</h3>
          <span className="mr-2">{ screenSize }</span>
          { _allowEdit &&
            <a
              onClick={ navigate(displayStep) }
              className="text-blue-500 cursor-pointer">Edit</a>
          }
        </div> }

        { (!order.isEGift && !destination.digital) && <div className="mb-4">
          <h3 className="font-bold inline-block mr-4">Cover</h3>
          { _allowEdit &&
            <a
              onClick={ navigate(coverStep) }
              className="text-blue-500 cursor-pointer">Edit</a>
          }
          <div className="mt-2 flex gap-2">
            <CoverImage coverId={ destination.coverId || '' } className="inline-block" />
            { !!destination.backCoverId && (<CoverImage coverId={ destination.backCoverId } className="inline-block" />) }
          </div>
          <div>
            { destination.extras?.find(extra => extra.id === 'giftbox-5in') ? <>+ gift box [$12]</> : null }
          </div>
        </div> }

        <h3 className="font-bold inline-block mr-4">Gift Message</h3>
        { _allowEdit &&
          <a
            onClick={ navigate(messageStep) }
            className="text-blue-500 cursor-pointer">Edit</a>
        }
        { destination.insideCoverId ? (
          <div className="mt-2">
            <CoverImage coverId={ destination.insideCoverId } className="inline-block" />
          </div>
        ) : (
          <GiftMessage message={ destination.giftMessage?.body } />
        ) }
      </div>

      <div className="col-span-12 md:col-span-3 mb-4 md:mb-0">
        <h3 className="font-bold inline-block mr-4">{ order.isEGift ? 'Email' : 'Address' }</h3>
        { _allowEdit &&
          <a
            onClick={ navigate(addressStep) }
            className="text-blue-500 cursor-pointer">Edit</a>
        }
        <AddressSummary address={ destination.address || null } isEGift={ order.isEGift } />
      </div>

      <div className="col-span-12 md:col-span-3 mb-4 md:mb-0">
        { (!order.isEGift && !destination.digital) && <>
          <h3 className="font-bold">Shipping</h3>
          { _allowEdit
            ? <ShippingOptionsForm destination={ destination } order={ order } />
            : <ShippingChoice destination={ destination } />
          }
        </> }
      </div>

      <div className="col-span-12 md:col-span-3 mb-4 md:mb-0">
        <h3 className="font-bold">Quantity</h3>
        { _allowEdit
          ? <QuantityForm destination={ destination } order={ order } designId={ designId } />
          : <p>Quantity: { destination.quantity }</p>
        }

        { order.isReplacement
          ? <>
            <h3 className="font-bold mt-8 md:mt-4">Subtotal</h3>
            <div className="max-w-sm">
              $0 <span className="text-sm">(if original returned)</span>
            </div>
          </>
          : (
            !!costs && (costs.total.listInCents > 0 || !!costs.balanceUsed.productBalanceUsed?.length) &&
            <>
              <h3 className="font-bold mt-8 md:mt-4">Subtotal</h3>
              <div className="max-w-sm">
                <PricingSummary
                  costs={ costs }
                  isEGift={ order.isEGift }
                  fields={ ["subtotal", "shipping"] }
                />
              </div>
            </>
          )
        }
      </div>
    </div>
  </>
}
