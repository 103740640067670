import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import MessageForm, { MessageFormOptions } from '../components/MessageForm'
import DestComponentEditor from '../components/DestComponentEditor'
import useSteps, { BuilderFlow, GiftFlow, EGiftFlow, MessageStep, GiftMessageStep, EGiftMessageStep, CoverStep, removeBoxStepIfSevenInch } from '../utils/Steps'
import { useStepContext } from '../utils/StepContext'
import { CircularProgress } from '@mui/material'
import { useRedirectWithDesignDestination } from '../utils/RedirectWithDesignDestination'
import { useAuthenticatedQuery } from '../apollo-client'
import { OrderDocument, OrderQuery } from '../graphql/__generated__'
import { findDestination } from '../utils/Destination'
import { isCustomCover } from '../utils/Covers'

interface MessageEditorProps {
  isEGift?: boolean
  isGift?: boolean
}

export default function MessageEditor({ isEGift, isGift }: MessageEditorProps) {
  const { orderId, designId, destinationId } = useParams()

  const step = (() => {
    if (isEGift) {
      return EGiftMessageStep
    }
    if (isGift) {
      return GiftMessageStep
    }
    return MessageStep
  })()

  const { navigateNext, navigatePrev, navigateTo, currentPath, currentStep, hasReturn } = useSteps()

  const { loading } = useRedirectWithDesignDestination({
    orderId, designId, destinationId,
    skip: currentStep !== step,
    currentPath,
  })

  const {
    data: { orderStatus: order } = {},
  } = useAuthenticatedQuery<OrderQuery>(OrderDocument, {
    variables: {
      orderId,
    },
  })

  const destination = order && destinationId ? findDestination(order, destinationId) : undefined
  const bookType = destination?.bookType

  const { setStepState } = useStepContext()
  useEffect(() => {
    setStepState({
      flow: (() => {
        var flow = BuilderFlow
        if (isEGift) {
          flow = EGiftFlow
        } else if (isGift) {
          flow = GiftFlow
        }
        return removeBoxStepIfSevenInch(flow, bookType)
      })(),
      step,
      args: { orderId, destinationId, designId },
    })
  }, [isEGift, isGift, bookType, orderId, destinationId, designId])

  const isCustomCoverUsed = !!destination?.insideCoverId && isCustomCover(destination.insideCoverId)

  const [choice, setChoice] = useState<"message" | "custom" | null>(null);
  useEffect(() => {
    if (isCustomCoverUsed) {
      setChoice('custom')
    }
    if (destination?.giftMessage?.body) {
      setChoice('message')
    }
  }, [destination, isCustomCoverUsed])

  if (!orderId) {
    return <>Missing order id</>
  }

  if (loading) {
    return <div className="flex my-4 justify-center">
      <CircularProgress />
    </div>
  }

  return <>
    <DestComponentEditor<MessageFormOptions>
      form={ MessageForm }
      formOptions={{
        isEGift,
        onCustomCoverEdit: currentStep && orderId && designId && destinationId ? (customCoverId => {
          navigateTo(currentStep, {
            orderId, designId, destinationId,
            coverId: customCoverId,
          }, {})
        }) : undefined,
        choice,
        setChoice,
      }}
      text={{
        pageTitle: isEGift ? 'Message' : 'Inside Cover',
        pageDescription: isEGift ? undefined : <div className="bg-gray-50 p-4 space-y-2">
          <p>Include a personalized message on the inside of the cover of your book, opposite the video player when the book is opened.</p>
        </div>,
        componentName: isEGift ? 'Message' : 'Inside Cover',
        addMoreHint: isEGift ? undefined : 'Want to send multiple books with different inside covers?',
      }}
      showSkip={ true }
      orderId={ orderId }
      destinationId={ destinationId }
      designId={ designId }
      hasReturn={ hasReturn }
      onDone={ () => {
        navigateNext({})
      }}
      onBack={ () => {
        hasReturn ? navigateNext({}) : navigatePrev({})
      }}
    />
  </>
}
