import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { useForm } from 'react-hook-form'
import type { FormRef, DestFormProps } from './DestComponentEditor'
import { BookType, Maybe, UpdateDestinationBookTypeDocument, UpdateDestinationBookTypeMutation } from '../graphql/__generated__'
import { destinationDisplaySize, DisplaySize, findDestination } from '../utils/Destination'
import { classNames } from '../utils/classes'
import { formatPrice, REFETCH_ON_COST_CHANGE } from '../utils/Price'
import { useMutation } from '@apollo/client'

export type DisplayFormFields = {
  size: Maybe<DisplaySize>
}

export type DisplayFormOptions = {
  setSize: React.Dispatch<Maybe<DisplaySize>>
}

const DisplayForm = forwardRef<FormRef, DestFormProps>(({ options, destination, onSave, onError, stock }, formRef) => {
  const [error, setError] = useState<string | undefined>();
  useEffect(() => setError(undefined), [options?.size])

  const [
    updateDestinationBookType,
    { loading: saving, error: savingError },
  ] = useMutation<UpdateDestinationBookTypeMutation>(UpdateDestinationBookTypeDocument, {
    refetchQueries: REFETCH_ON_COST_CHANGE,
  })

  const submit = async function (values: DisplayFormFields) {
    if (!destination?.id) {
      throw new Error("No destination provided")
    }

    const bookType: Maybe<BookType> = (() => {
      switch (values.size) {
        case 5:
          return BookType.FiveInch
        case 7:
          return BookType.SevenInch
        default:
          return null
      }
    })()

    if (!bookType) {
      return setError("Display size must be selected before continuing.")
    }
    
    await updateDestinationBookType({
      variables: {
        destinationId: destination.id,
        bookType,
      },
      onError: err => onError?.(err.message),
      onCompleted: ({ updateDestinationBookType: _order }) => {
        const result = findDestination(_order, destination.id)
        result && onSave?.(result)
      },
    })
  }
  
  const { handleSubmit, reset, getValues, setValue, watch } = useForm<DisplayFormFields>()
  
  useEffect(() => reset({
    size: destinationDisplaySize(destination),
  }), [reset, destination])
  
  const errorHandler = useCallback(
    () => onError?.("Check validation errors on form"),
    [onError],
  )
  
  useImperativeHandle(formRef, () => ({
    saving,
    submit: handleSubmit(submit, errorHandler),
  }), [saving, errorHandler])

  const size = getValues("size")
  useEffect(() => {
    options.setSize(size)
  }, [size, options.setSize])
  watch("size")

  return <div className="mx-auto md:mx-4 space-y-4">
    <div className="flex gap-4 md:gap-6">
      <div className="hidden sm:flex max-w-[50%]">
        <img src={ `${ process.env.PUBLIC_URL }/images/display-comparison.png` } />
      </div>
      <div className="flex flex-col self-center sm:pt-8 md:pt-4 gap-4 sm:gap-16 md:gap-20">
        <label className="inline-flex">
          <input
            data-cy="display-option-7in"
            type="radio"
            className="
              flex-shrink-0
              cursor-pointer
              h-8
              w-8
              mt-[3px]
              checked:bg-black
              checked:border-transparent
              focus:outline-none
            "
            checked={size === 7}
            onClick={e => {
              e.preventDefault()
              setValue("size", 7)
            }}
          />
          <p className="flex-col ml-4">
            <span className="block text-lg">
              7" Display <span className="whitespace-nowrap">(only +{ formatPrice(2900) })</span>
            </span>
            <span className="block text-gray-600">
              Upgrade your book to a 7" display, including larger speakers and 50% more screen area.
            </span>
          </p>
        </label>
        <label className="inline-flex">
          <input
            data-cy="display-option-5in"
            type="radio"
            className="
              flex-shrink-0
              cursor-pointer
              h-8
              w-8
              mt-[3px]
              checked:bg-black
              checked:border-transparent
              focus:outline-none
            "
            checked={size === 5}
            onClick={e => {
              e.preventDefault()
              setValue("size", 5)
            }}
          />
          <p className="flex-col ml-4">
            <span className="block text-lg">
              5" Display <span className="whitespace-nowrap">(starting at { formatPrice(4900)})</span>
            </span>
            <span className="block text-gray-600">
              Our standard sized book has a 5" display, with speakers for music.
            </span>
          </p>
        </label>
      </div>
    </div>
    { !!error && <p className="p-2 m-4 bg-red-300">{ error }</p> }
    { !!savingError && <p className="p-2 m-4 bg-red-300">{ savingError.message }</p> }
  </div>
})

export default DisplayForm
