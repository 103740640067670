import { useCallback, useEffect, useMemo } from 'react'
import { makeVar, useReactiveVar } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useStepContext } from './StepContext'
import { useAuthenticatedQuery } from '../apollo-client'
import { BookType, OrderDocument, OrderFragment } from '../graphql/__generated__'

export type StepArgs = {[key: string]: string | boolean | undefined} & {
  orderId?: string
  designId?: string
  destinationId?: string
  coverId?: string
}

export type PathFn = (args: StepArgs) => string

const NullPathFn: PathFn = () => ''

export type Step = {
  name: string
  status?: string
  default?: boolean
  showInNav?: boolean
  showNav?: boolean
  path: PathFn
}

type StepWithArgs = {
  step: Step
  args: StepArgs
}

export type NavigateOptions = {
  returnHere?: boolean
  queryParams?: URLSearchParams
}

function generateDestPath(name: string, { orderId, designId, destinationId, add }: StepArgs, prefix?: string){
  if (!orderId) {
    return ''
  }

  let out = `/order/${ orderId }`
  if (designId) {
    out += `/design/${ designId }`
  }
  if (destinationId) {
    out += `/destination/${ destinationId }`
  }
  if (name !== '') {
    out += `/${ name }`
  }
  if (add) {
    out += `?add=true`
  }
  if (prefix) {
    out = `/${prefix}${out}`
  }
  return out
}

export const OverviewStep: Step = {
  name: 'Overview',
  path: () => '/',
  showInNav: false,
  showNav: false,
}

export const DisplayStep: Step = {
  name: 'Display',
  path: (options: StepArgs) => generateDestPath('display', options),
}

export const CoverStep: Step = {
  name: 'Cover',
  path: (options: StepArgs) => generateDestPath(
    `cover${options.coverId ? `/${options.coverId}` : ""}`,
    options
  ),
}

export const MediaStep: Step = {
  name: 'Media',
  path: (options: StepArgs) => {
    let maybeSuffix = ""
    if (!options.designId) {
      maybeSuffix = "/design"
      options.destinationId = undefined
    }
    return generateDestPath('', options) + maybeSuffix
  }
}

export const AddressStep: Step = {
  name: 'Address',
  path: (options: StepArgs) => generateDestPath('address', options),
}

export const MessageStep: Step = {
  name: 'Message',
  path: (options: StepArgs) => generateDestPath(
    `message${options.coverId ? `/cover/${options.coverId}` : ""}`,
    options
  ),
}

export const BoxStep: Step = {
  name: 'Box',
  path: (options: StepArgs) => generateDestPath('box', options),
}

export const ReviewStep: Step = {
  name: 'Review',
  default: true,
  path: ({ orderId }) => `/order/${orderId}`,
}

export const CheckoutStep: Step = {
  name: 'Checkout',
  path: ({ orderId }) => `/order/${orderId}/payment`,
}

export const ThanksStep: Step = {
  name: 'Thanks',
  showInNav: false,
  showNav: false,
  path: ({ orderId }) => `/order/${orderId}/status?thanks=true`,
}

export const OrderStatusStep: Step = {
  name: 'Status',
  showInNav: false,
  showNav: false,
  path: ({ orderId }) => `/order/${orderId}/status`,
}

export const LocalWelcomeStep: Step = {
  name: 'Welcome',
  path: () => `/local`,
  showInNav: false,
}

export const LocalMediaStep: Step = {
  name: 'Media',
  path: ({ designId }) => designId && `/local/design/${designId}/media` || '',
}

export const OrientationStep: Step = {
  name: 'Orientation',
  path: ({ designId }) => {
    let out = `/local/design`
    if (designId) {
      out += `/${designId}`
    }
    return out
  }
}

export const AssembleStep: Step = {
  name: 'Assemble',
  path: ({ designId }) => designId && `/local/design/${ designId }/assemble` || '',
}

export const DownloadStep: Step = {
  name: 'Download',
  path: ({ designId }) => designId && `/local/design/${designId}/download` || '',
}

export const TransferStep: Step = {
  name: 'Transfer',
  path: ({ designId }) => designId && `/local/design/${designId}/transfer` || '',
}

export const GiftDisplayStep: Step = {
  name: 'Display',
  path: (options: StepArgs) => generateDestPath('display', options, 'gift'),
}

export const GiftCoverStep: Step = {
  name: 'Cover',
  path: (options: StepArgs) => generateDestPath(
    `cover${options.coverId ? `/${options.coverId}` : ""}`,
    options,
    'gift'
  ),
}

export const GiftAddressStep: Step = {
  name: 'Address',
  path: (options: StepArgs) => generateDestPath('address', options, 'gift'),
}

export const GiftMessageStep: Step = {
  name: 'Message',
  path: (options: StepArgs) => generateDestPath('message', options, 'gift'),
}

export const GiftBoxStep: Step = {
  name: 'Box',
  path: (options: StepArgs) => generateDestPath('box', options, 'gift'),
}

export const GiftReviewStep: Step = {
  name: 'Review',
  default: true,
  path: ({orderId}: StepArgs) => generateDestPath('', { orderId }, 'gift'),
}

export const GiftCheckoutStep: Step = {
  name: 'Checkout',
  path: ({orderId}: StepArgs) => generateDestPath('payment', { orderId }, 'gift'),
}

export const EGiftEmailStep: Step = {
  name: 'Email',
  path: (options: StepArgs) => generateDestPath('email', options, 'e-gift'),
}

export const EGiftMessageStep: Step = {
  name: 'Message',
  path: (options: StepArgs) => generateDestPath('message', options, 'e-gift'),
}

export const EGiftReviewStep: Step = {
  name: 'Review',
  default: true,
  path: ({orderId}: StepArgs) => generateDestPath('', { orderId }, 'e-gift'),
}

export const EGiftCheckoutStep: Step = {
  name: 'Checkout',
  path: ({orderId}: StepArgs) => generateDestPath('payment', { orderId }, 'e-gift'),
}

export const CustomCoverStep: Step = {
  name: 'Custom Cover',
  path: ({coverId}: StepArgs) => `/cover/${ coverId || '' }`,
}

const compareFlows = (flow1: Step[], flow2: Step[]): boolean => {
  if (flow1.length !== flow2.length) {
    return false
  }
  for (let i=0; i<flow1.length; i++) {
    if (flow1[i].name !== flow2[i].name) {
      return false
    }
  }
  return true
}

export const GiftFlow: Step[] = [
  GiftDisplayStep,
  GiftCoverStep,
  GiftAddressStep,
  GiftMessageStep,
  GiftBoxStep,
  GiftReviewStep,
  GiftCheckoutStep,
  ThanksStep,
]

const isGiftFlow = (flow: Step[]) => compareFlows(flow, GiftFlow)

export const EGiftFlow: Step[] = [
  EGiftEmailStep,
  EGiftMessageStep,
  EGiftReviewStep,
  EGiftCheckoutStep,
  ThanksStep,
]

const isEGiftFlow = (flow: Step[]) => compareFlows(flow, EGiftFlow)

export const BuilderFlow: Step[] = [
  OverviewStep,
  DisplayStep,
  CoverStep,
  MediaStep,
  AddressStep,
  MessageStep,
  BoxStep,
  ReviewStep,
  CheckoutStep,
  ThanksStep,
]

const isBuilderFlow = (flow: Step[]) => compareFlows(flow, BuilderFlow)

export const LocalFlow: Step[] = [
  LocalWelcomeStep,
  OrientationStep,
  LocalMediaStep,
  AssembleStep,
  DownloadStep,
  TransferStep,
]

type FlowLabels = 'GiftFlow' | 'EGiftFlow' | 'BuilderFlow' | 'LocalFlow'
const flows: { [key in FlowLabels ]: Step[] } = {
  'GiftFlow': GiftFlow,
  'EGiftFlow': EGiftFlow,
  'BuilderFlow': BuilderFlow,
  'LocalFlow': LocalFlow,
}

export const removeBoxStepIfSevenInch = (flow: Step[], bookType: BookType | null | undefined) => {
  // gift boxes not purchased for 7" books yet
  if (bookType === BookType.SevenInch) {
    return flow.filter(s => s.name !== BoxStep.name)
  }
  return [...flow]
}

const RETURN_TO = makeVar<StepWithArgs | null>(null)

// Helpful for making sure the correct URL/Flow is being used when accessing a [E]Gift Order.
function useRedirectOrderToCorrectFlow(){
  const navigate = useNavigate()

  const { flow: currentFlow, step: currentStep, args: currentArgs } = useStepContext()
  const isOrderFlow = currentFlow && (
    isGiftFlow(currentFlow) ||
    isEGiftFlow(currentFlow) ||
    isBuilderFlow(currentFlow)
  )

  let skipOrderQuery = !isOrderFlow || !currentArgs?.orderId || !currentStep;
  if (currentStep?.name === 'Thanks') {
    // Thanks step is shared between all flows and steps aren't rendered.
    skipOrderQuery = true
  }
  if (currentStep?.name === 'Gift') {
    // Don't redirect away from the initial Gift step, since we may not have necessary metadata yet.
    skipOrderQuery = true
  }

  const { data: { orderStatus: order } = {} } = useAuthenticatedQuery<
    { orderStatus: OrderFragment }
  >(OrderDocument, {
    skip: skipOrderQuery,
    variables: {
      orderId: currentArgs?.orderId,
    },
  })

  useEffect(() => {
    if (!order || !currentFlow || !currentStep) {
      return
    }

    let expectedFlow: FlowLabels | undefined
    if (order.isGift) {
      expectedFlow = order.isEGift ? 'EGiftFlow' : 'GiftFlow'
    } else {
      // EGift shouldn't be true if Gift is false.
      expectedFlow = 'BuilderFlow'
    }

    if (!expectedFlow || compareFlows(flows[expectedFlow], currentFlow)) {
      return
    }

    // Determine the best matching step for the new flow; falling back to Review as needed.
    let newStep: Step | undefined
    switch (expectedFlow) {
      case 'GiftFlow':
        switch (currentStep.name) {
          case 'Display':
            newStep = GiftDisplayStep
            break
          case 'Cover':
            newStep = GiftCoverStep
            break
          case 'Address':
          case 'Email':
            newStep = GiftAddressStep
            break
          case 'Message':
            newStep = GiftMessageStep
            break
          case 'Box':
            newStep = GiftBoxStep
            break
          case 'Review':
            newStep = GiftReviewStep
            break
          case 'Checkout':
            newStep = GiftCheckoutStep
            break
          default:
            newStep = GiftReviewStep
        }
        break
      case 'EGiftFlow':
        switch (currentStep.name) {
          case 'Address':
          case 'Email':
            newStep = EGiftEmailStep
            break
          case 'Message':
            newStep = EGiftMessageStep
            break
          case 'Review':
            newStep = EGiftReviewStep
            break
          case 'Checkout':
            newStep = EGiftCheckoutStep
            break
          default:
            newStep = EGiftReviewStep
        }
        break
      case 'BuilderFlow':
        switch (currentStep.name) {
          case 'Display':
            newStep = DisplayStep
            break
          case 'Cover':
            newStep = CoverStep
            break
          case 'Address':
          case 'Email':
            newStep = AddressStep
            break
          case 'Message':
            newStep = MessageStep
            break
          case 'Box':
            newStep = BoxStep
            break
          case 'Review':
            newStep = ReviewStep
            break
          case 'Checkout':
            newStep = CheckoutStep
            break
          default:
            newStep = ReviewStep
        }
        break
    }

    // Navigate to expected flow's URL after determining the corresponding step.
    if (newStep) {
      navigate(newStep.path(currentArgs ?? {}), { replace: true })
    }
  }, [order, currentFlow, currentStep, currentArgs])
}

export default function useSteps(){
  const navigate = useNavigate()

  const { flow: currentFlow, step: currentStep, args: currentArgs } = useStepContext()
  const returnTo = useReactiveVar(RETURN_TO)

  useRedirectOrderToCorrectFlow()

  let steps: Step[] | null = null
  if (currentFlow){
    steps = currentFlow.slice()
    let foundCurrentStep = false
    for (let i = 0; i < currentFlow.length; i++) {
      if (steps[i].name === currentStep?.name) {
        steps[i].status = 'current'
        foundCurrentStep = true
      } else if (foundCurrentStep) {
        steps[i].status = 'upcoming'
      } else {
        steps[i].status = 'complete'
      }
    }
  }

  const path = useCallback(
    (step: Step, args: StepArgs = {}) => step.path(Object.assign({}, currentArgs, args)) || '',
    [currentArgs],
  )

  const { nextPath, prevPath, currentPath } = useMemo(() => {
    if (!currentStep || !currentFlow) {
      return {
        nextPath: NullPathFn,
        prevPath: NullPathFn,
        currentPath: NullPathFn,
      }
    }

    const stepIndex = currentFlow.findIndex(step => step.name === currentStep.name)
    const nextStep = currentFlow[stepIndex + 1]
    const prevStep = currentFlow[stepIndex - 1]

    return {
      nextPath: returnTo
        ? (args: StepArgs) => path(returnTo.step, Object.assign({}, (returnTo.args || {}), args))
        : (args: StepArgs) => nextStep ? path(nextStep, args) : '',
      prevPath: (args: StepArgs) => prevStep ? path(prevStep, args) : '',
      currentPath: (args: StepArgs) => path(currentStep, args),
    }
  }, [currentStep, currentFlow, returnTo, path])

  const navigateNext = useCallback((args: StepArgs) => {
    RETURN_TO(null)

    if (nextPath) {
      const path = nextPath(args)
      if (path) {
        navigate(path)
      }
    }
  }, [navigate, nextPath])

  const navigatePrev = useCallback((args: StepArgs) => {
    if (prevPath) {
      const path = prevPath(args)
      if (path) {
        navigate(path)
      }
    }
  }, [navigate, prevPath])

  const navigateTo = useCallback((step: Step, args: StepArgs, options: NavigateOptions) => {
    if (options.returnHere && currentStep) {
      RETURN_TO({step: currentStep, args: currentArgs || {}})
    }

    var url = step.path(Object.assign({}, currentArgs, args))
    if (options.queryParams) {
      url += (url.indexOf('?') !== -1 ? '&' : '?') + options.queryParams.toString()
    }

    navigate(url)
  }, [currentStep])

  return {
    currentStep,
    currentArgs,
    currentFlow,
    steps,
    path,
    nextPath,
    prevPath,
    currentPath,
    navigateNext,
    navigatePrev,
    navigateTo,
    hasReturn: !!returnTo,
    returnStep: returnTo?.step,
    returnArgs: returnTo?.args,
  }
}
