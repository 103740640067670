import React, { useRef, useCallback, useEffect, useState, useMemo } from 'react'
import type { FormEvent } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import CircularProgress from '@mui/material/CircularProgress'

import Button from '../components/Button'
import ButtonLink from '../components/ButtonLink'
import DiscountForm from '../components/DiscountForm'
import OrderPricingSummary from '../components/OrderPricingSummary'
import OrderBalanceUsage from '../components/OrderBalanceUsage'
import NoteField from '../components/NoteField'
import { OrderDocument, CompleteFreeOrderDocument, UpdateMarketingSubscriptionDocument, OrderQuery, OrderQueryVariables, BookType } from '../graphql/__generated__'
import PaymentFormInput, { PaymentFormInputRef } from '../components/PaymentFormInput'
import useSteps, { BuilderFlow, GiftFlow, EGiftFlow, CheckoutStep, GiftCheckoutStep, EGiftCheckoutStep, removeBoxStepIfSevenInch } from '../utils/Steps'
import { useStepContext } from '../utils/StepContext'
import { useAuthenticatedQuery, useAuthentication } from '../apollo-client'
import { useOrderValidation } from '../utils/OrderValidation'
import { gtagBeginCheckout, gtagPurchaseAmount } from '../utils/GTag'

declare let gtag: Function

/* Note that some payment methods are 'delayed', meaning we won't
 * hear about their failure in the UI, only through a hook later.
 *
 * We don't currently even listen to this hook, but we will need to,
 * and will need to notify the user of failures, if we ever want
 * to support delayed payment methods like ACH and Buy Now/Pay Later.
 */

interface CheckoutProps {
  isEGift?: boolean
  isGift?: boolean
}

export default function Checkout({ isEGift, isGift }: CheckoutProps) {
  const { orderId } = useParams()

  const [optInToMarketing, setOptInToMarketing] = useState<boolean>(true)
  const [showNote, setShowNote] = useState<boolean>(false)

  const {
    data: { orderStatus: order, stock } = {},
    loading,
    error,
  } = useAuthenticatedQuery<OrderQuery, OrderQueryVariables>(OrderDocument, {
    skip: !orderId,
    variables: {
      orderId: orderId ?? '',
    },
  })
    
  // only care if all are 7", then hide Box step
  const bookTypes = new Set<BookType>()
  order?.designs?.forEach(design => design?.destinations?.forEach(dest => {
      if (dest.bookType) {
        bookTypes.add(dest.bookType)
      } else {
        bookTypes.add(BookType.FiveInch)
      }
  }))
  const bookType = bookTypes.size === 1 ? Array.from(bookTypes)[0] : null

  const { setStepState } = useStepContext()
  useEffect(() => {
    setStepState({
      flow: (() => {
        var flow = BuilderFlow
        if (isEGift) {
          flow = EGiftFlow
        } else if (isGift) {
          flow = GiftFlow
        }
        return removeBoxStepIfSevenInch(flow, bookType)
      })(),
      step: (() => {
        if (isEGift) {
          return EGiftCheckoutStep
        }
        if (isGift) {
          return GiftCheckoutStep
        }
        return CheckoutStep
      })(),
      args: { orderId },
    })
  }, [isEGift, isGift, bookType, orderId])

  const { nextPath, navigateNext, navigatePrev } = useSteps()

  const [updateMarketingSubscription, { loading: updatingMarketingSubscription }] = useMutation(UpdateMarketingSubscriptionDocument)

  const { customerData } = useAuthentication()

  const [completeFreeOrder, { error: errorCompletingFree, loading: completingFree }] = useMutation(CompleteFreeOrderDocument, {
    variables: {
      orderId,
    },
  })

  const orderListCost = order?.cost?.total.total.listInCents ?? 0
  const orderActualCost = order?.cost?.total.total.actualInCents ?? 0

  useEffect(() => {
    if (order?.notes){
      for (let i=0; i < order.notes.length; i++){
        if (order.notes[i].createdBy.name === 'customer') {
          setShowNote(true)
        }
      }
    }
  }, [order?.notes])

  const optedInToMarketing = customerData?.subscribedToMarketingEmails

  // Return to Review step if order's designs/destinations have validation problems.
  const { hasProblems } = useOrderValidation(order, stock)
  useEffect(() => {
    if (hasProblems) {
      navigatePrev({})
    }
  }, [hasProblems, navigatePrev])

  const paymentRef = useRef<PaymentFormInputRef | null>(null)

  useEffect(() => {
    if (order?.id) {
      gtagBeginCheckout(order.id)
    }
  }, [order?.id])

  const afterPayment = useCallback((amount: number, orderId: string) => {
    if (amount > 0){
      // If it's a free order, we probably already tracked the purchase event when
      // they bought through the vendor or on sendheirloom.com
      gtagPurchaseAmount(orderId, amount)
    }

    navigateNext({
      thanks: true,
    })
  }, [navigateNext])

  const submit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (optInToMarketing) {
      updateMarketingSubscription({
        variables: {
          subscribe: true,
        }
      })
    }

    if (orderActualCost > 0 && !order?.isReplacement) {
      paymentRef.current?.submit()
    } else if (orderId) {
      const { errors } = await completeFreeOrder()
      if (!Object.keys(errors ?? {}).length) {
        afterPayment(0, orderId)
      }
    }
  }, [optInToMarketing, orderActualCost, orderId, afterPayment, order?.isReplacement])

  // we don't pass an entire order fragment to the PaymentFormInput dependencies, b/c every render has an obvious loading animation
  // for example, changes to an order's notes should not reload Stripe -- that only needs to be done if the cost changes
  // however, PaymentFormInput needs to update the order fragment in Apollo cache, requiring __typename; see: Apollo cache.identify()
  const orderCacheIdentifyObj = useMemo(() => order?.__typename ? {
    __typename: order.__typename,
    id: order.id,
  } : undefined, [order?.__typename, order?.id])

  const stripeForm = useMemo(() => !!orderId && !!orderCacheIdentifyObj && <PaymentFormInput
    orderId={ orderId }
    orderCacheIdentifyObj={ orderCacheIdentifyObj }
    orderCost={ orderActualCost }
    ref={ paymentRef }

    /* We have to pass the next path, as Stripe may redirect the page */
    nextPath={ nextPath({thanks: true}) }

    onSave={ () => {
      if (orderActualCost) {
        afterPayment(orderActualCost, orderId)
      }
    } }
    onError={ () => {
      // TODO
    } }
  />, [orderId, orderCacheIdentifyObj, orderActualCost, nextPath, afterPayment])

  if (loading) {
    return <div className="flex justify-center"><CircularProgress /></div>
  }
  if (error) {
    return <p className="my-4 p-2 bg-red-100">Error loading order.</p>
  }
  if (!orderId || !order?.designs) {
    return <div>Please add a book to your order before trying to complete your order.</div>
  }

  let singleBook = true
  let foundBook = false
  for (let i=0; i < order.designs.length; i++){
    const { destinations } = order.designs[i]
    if (!destinations) {
      continue
    }

    for (let j=0; j < destinations.length; j++){
      foundBook = true

      if (foundBook || destinations[j].quantity > 1){
        singleBook = false
        break
      }
    }
  }

  if (!foundBook) {
    return <div>Please add a destination address to your book to complete your order.</div>
  }

  const terms = singleBook ? {'book': 'book', 'it is': 'it is'} : {'book': 'books', 'it is': 'they are'}

  return <div className="max-w-xl m-auto">
    <h1 className="text-xl mb-6">Checkout</h1>

    { order.isEGift ?
      <div className="p-4 space-y-3 bg-gray-100 text-sm">
        <h2 className="text-xl pb-2">Next Steps</h2>
        <p>Thank you for your e-gift order!</p>
        <p>After your order is submitted we will automatically send your recipient their
           e-gift email. They will be able to use the link in that email to begin building
           their video book.</p>
      </div>
                  :
      <div className="p-4 space-y-3 bg-gray-100 text-sm">
        <h2 className="text-xl pb-2">A Message From The Heirloom Team</h2>
        <p>
          Building a video book isn't always easy; we appreciate your hard work and creativity.
          We are confident your { terms['book'] } will be exceptional. If for any reason { terms['it is'] } not,
          we will provide a full refund or replacement.
        </p>

        <p>
          Your { terms['book'] } will be shipped quickly after your order is placed. If you have any questions or
          concerns, please <a onClick={ () => setShowNote(true) } className="text-blue-500 cursor-pointer">add a note</a> to your order.
        </p>

        <p>
          Thank you for entrusting us with your cherished memories,
        </p>

        <div>
          <div className="font-bold">
            Ashley & Zack
          </div>
          <div>
            Co-founders
          </div>
        </div>
      </div>
    }

    { showNote && <>
      <NoteField order={ order } />
    </> }

    <div className="max-w-xl mx-auto my-4">
      { order &&
        <>
          { orderListCost > 0 &&
            <>
              <OrderPricingSummary order={ order } />

              { !order.isReplacement &&
                <div className="flex justify-end">
                  <DiscountForm order={ order } />
                </div>
              }
            </>
          }

          { !!order?.cost?.total?.balanceUsed?.productBalanceUsed?.length && (
            <div className="my-4">
              <OrderBalanceUsage order={ order } />
            </div>
          )}

          <form className="m-auto" onSubmit={ submit }>
            { orderActualCost > 0 && !order.isReplacement &&
              <div className="max-w-xl p-6">
                { stripeForm }
              </div>
            }

            { errorCompletingFree &&
              <p className="mb-4 p-2 bg-red-100">
                { errorCompletingFree.toString() }
              </p>
            }

            { !optedInToMarketing &&
              <div className="flex items-center p-4 bg-gray-50 rounded">
                <input
                  type="checkbox"
                  className="
                    flex-shrink-0 form-tick appearance-none curs h-6 w-6 mt-[3px]
                    border border-gray-300 rounded checked:bg-black checked:border-transparent
                    focus:outline-none
                  "
                  id="opt-in"
                  checked={ optInToMarketing }
                  onChange={ (e) => setOptInToMarketing(e.target.checked) }
                />
                <label className="ml-4 text-sm cursor-pointer" htmlFor="opt-in">
                  You may occasionally contact me about new products, updates, and discounts.
                </label>
              </div>
            }

            <div className="mt-4 grid grid-cols-2">
              <ButtonLink
                className="justify-self-start"
                to={ (isEGift ? '/e-gift' : (isGift ? '/gift' : '')) + `/order/${ order.id }` }
                type="secondary">Edit Order</ButtonLink>

              <Button
                className="justify-self-end"
                active={ completingFree || updatingMarketingSubscription }
                type="primary">Submit Order</Button>
            </div>
          </form>
        </>
      }
    </div>
  </div>
}
