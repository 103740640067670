import { type Maybe, type OrderFragment, type DestinationFragment, BookType } from '../graphql/__generated__'

export function findDestination(order: OrderFragment, destId: string): DestinationFragment | undefined {
  if (!!!order?.designs) {
    return undefined
  }

  for (let i=0; i < order.designs.length; i++){
    const design = order.designs[i]
    if (!!!design?.destinations) {
      continue
    }

    for (let j=0; j < design.destinations.length; j++){
      const dest = design.destinations[j]
      if (dest.id == destId) {
        return dest
      }
    }
  }

  return undefined
}

export type DisplaySize = 5 | 7

export function destinationDisplaySize(destination?: DestinationFragment): Maybe<DisplaySize> {
  switch (destination?.bookType) {
    case BookType.FiveInch:
      return 5
    case BookType.SevenInch:
      return 7
    default:
      return null
  }
}