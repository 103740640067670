import React, { useState, useMemo, useEffect, useRef } from 'react'
import type { RefObject } from 'react'
import { isMobile } from 'react-device-detect'

import createUppy from '../utils/uppy'
import DropTarget from '@uppy/drop-target'
import Webcam from '@uppy/webcam'
import Dashboard from '@uppy/dashboard'
import ScreenCapture from '@uppy/screen-capture'
import StatusBar from '@uppy/status-bar'
import Phone from './uppy/Phone'

import useWindowDimensions from '../utils/WindowDimensions'
import { CircularProgress } from '@mui/material'
import { classNames } from '../utils/classes'
import { UppyFile } from '@uppy/core'
import YouTube from './uppy/YouTube'

type UploaderProps = {
  children: JSX.Element
  onUploaded: (entry: {id: string}) => void
  dropContainer: RefObject<HTMLElement>
  showUpload: boolean
  onUploadShown: () => void
  waitingForSave: boolean // appendMedia GQL mutation
}

type NumToString = {
  [key: number]: string
}

const COMPANION_URL = localStorage.DEBUG_local_companion ? 'http://localhost:4323' : 'https://companion.sendheirloom.com'

export default function Uploader({ children, onUploaded, dropContainer, showUpload, onUploadShown, waitingForSave }: UploaderProps) {
  const [error, setError] = useState<string | undefined>(undefined)
  const [statusBarShown, setStatusBarShown] = useState(false)

  const { uppy, removePlugin } = useMemo(() => {
    const u = createUppy('media')

    let uploadStatus: {
      id: string
      complete?: boolean
    }[] = new Array()
    let pendingUploads: NumToString = {}
    let latestUpload: number = 0

    const reset = (hardReset: boolean) => {
      setStatusBarShown(false)

      if (hardReset) {
        // This helps reset the count of total files being uploaded, shown by StatusBar.
        // But, it has the added benefit of "disabling" Uppy's duplicate file prevention.
        // Only called when all uploads are complete; unnecessary when an upload is canceled.
        uppy.cancelAll()
      }

      uploadStatus = []
      pendingUploads = {}
      latestUpload = 0
    }

    const processPendingQueue = () => {
      // Files get uploaded in whatever order, but we want to preserve the order
      // they were added to the uploader.
      for (let i=latestUpload; i < uploadStatus.length; i++){
        if (pendingUploads[i]) {
          if (pendingUploads[i] !== '<error>') {
            onUploaded({id: pendingUploads[i]})
          }
          latestUpload++
        } else {
          break
        }
      }
    }

    u.on('file-added', ({ id }) => {
      uploadStatus.push({ id })

      setError(undefined)
      setStatusBarShown(true)
    })

    u.on('upload-success', (file, response) => {
      if (!file) {
        return;
      }

      const url = response.uploadURL

      // It's a bit of a wonky process to get the id from the upload response, as
      // tus doesn't make it easy to return the actual destination location.
      const id = url?.split('+')[0].split('/').pop()
      if (!id) {
        console.error("Unable to determine ID from upload URL", response)
        return
      }

      let index = 0
      for (let i=0; i < uploadStatus.length; i++) {
        if (uploadStatus[i]?.id === file.id) {
          index = i
          break
        }
      }

      pendingUploads[index] = id
      processPendingQueue()
    })

    u.on('complete', ({ failed, successful }) => {
      const markAsComplete = ({ id }: UppyFile) => {
        const file = uploadStatus.find(({ id: id2 }) => id2 === id)
        if (file) {
          file.complete = true
        }
      }

      failed.forEach(markAsComplete)
      successful.forEach(markAsComplete)

      let allComplete = true
      uploadStatus.forEach(({ complete }) => {
        if (!complete) {
          allComplete = false
        }
      })

      if (allComplete) {
        reset(true)
      }
    })
    u.on('cancel-all', () => reset(false))

    u.on('upload-error', (err: any) => {
      console.error("Error uploading", err)
      setError(err.uploadResponse ?? "Error uploading")
    })

    return {
      uppy: u,
      removePlugin: (pluginId: string) => {
        const plugin = u.getPlugin(pluginId)
        if (plugin) {
          u.removePlugin(plugin)
        }
      },
    }
  }, [])

  useEffect(() => {
    uppy.use(Webcam, {
      id: 'Webcam',
      modes: ['video-audio'],
      showRecordingLength: true,
      showVideoSourceDropdown: true,
      videoConstraints: {
        width: { ideal: 1280 },
        height: { ideal: 720 },
        facingMode: 'environment',
      },
      locale: {
        strings: {
          pluginNameCamera: (isMobile ? 'Camera' : 'Webcam'),
        },
      },
    })

    // Not using GoogleDrive because we are getting a client_id missing error.
    // Not using Facebook because we haven't figured out how to authorize getting videos.

    uppy.use(YouTube, {
      id: 'YouTube',
      companionUrl: COMPANION_URL,
      title: 'URL Link',
    })

    // Consider using GoldenRetriever for caching via local storage.

    if (!isMobile) {
      uppy.use(Phone, { id: 'Phone' })

      uppy.use(ScreenCapture, {
        id: 'ScreenCapture',
        // @ts-ignore They neglected to include this in the type
        title: 'Capture Screen',

        displayMediaConstraints: {
          video: {
            width: 1280,
            height: 720,
            aspectRatio: 16 / 9,
            frameRate: {
              ideal: 30,
              max: 30,
            },
            cursor: 'never',
            displaySurface: 'monitor',
          },
        },
        preferredVideoMimeType: 'video/mp4',
      })
    }

    return () => ['Webcam', 'YouTube', 'Phone', 'ScreenCapture'].forEach(removePlugin)
  }, [uppy, isMobile, removePlugin])

  // used for Uppy plugins requiring DOM elements to be rendered for targeting
  const [loaded, setLoaded] = useState(false)
  useEffect(() => setLoaded(true), [])

  useEffect(() => {
    if (!loaded) {
      // #status-bar-target and dropContainer aren't rendered yet
      return
    }

    uppy.use(StatusBar, {
      id: 'StatusBar',
      target: '#status-bar-target',
      hideAfterFinish: true,
      showProgressDetails: true,
      hideUploadButton: false,
      hideRetryButton: false,
      hidePauseResumeButton: false,
      hideCancelButton: false,
    })

    if (dropContainer.current) {
      uppy.use(DropTarget, {
        id: 'DropTarget',
        target: dropContainer.current,
      })
    }

    return () => ['StatusBar', 'DropTarget'].forEach(removePlugin)
  }, [loaded, uppy, removePlugin])

  const dashContainer = useRef<HTMLDivElement>(null)
  const { width } = useWindowDimensions()

  useEffect(() => {
    if (!loaded) {
      // dashContainer isn't rendered yet
      return
    }

    const plugins = ['Webcam', 'YouTube']
    if (!isMobile) {
      plugins.push('Phone')
      plugins.push('ScreenCapture')
    }

    if (dashContainer.current) {
      uppy.use(Dashboard, {
        target: dashContainer.current,
        plugins: plugins,
        inline: true,
        fileManagerSelectionType: 'both',
        proudlyDisplayPoweredByUppy: false,
        hideProgressAfterFinish: true,
        showProgressDetails: true,
        showSelectedFiles: false,
        disableStatusBar: true,
        width: '100%',
        locale: {
          strings: {
            myDevice: isMobile ? 'My Device' : 'My Computer',
          },
        },
      })

      if (showUpload) {
        const button: HTMLButtonElement | null = dashContainer.current.querySelector('button.uppy-Dashboard-browse')
        if (button) {
          button.click()
        }
        onUploadShown()
      }
    }

    return () => removePlugin('Dashboard')
  }, [loaded, isMobile, showUpload, onUploadShown, width, removePlugin])

  return <div className="min-w-full">
    { error &&
      <div className="p-2 my-2 bg-red-100">
        There was an error uploading your photos and videos. Please reload the page to try again.
      </div>
    }

    { children }

    <div ref={ dashContainer }></div>

    <div className={ classNames(
        statusBarShown || waitingForSave ? "" : "hidden",
        "backdrop-blur overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full justify-center items-center flex",
    ) }>
      <div className="relative w-full max-w-md h-full md:h-auto pt-2 md:pt-0">
        <div className="relative p-4 bg-white rounded-lg text-center space-y-4">
          <p>{ statusBarShown ? "Uploading to Server" : "Adding to Design" }...</p>
          <div id="status-bar-target" className={ statusBarShown ? "" : "hidden" }></div>
          { !statusBarShown && <CircularProgress /> }
        </div>
      </div>
    </div>
  </div>
}
