import React, { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react'
import { useMutation } from '@apollo/client'
import { useForm, FormProvider } from 'react-hook-form'
import { UpdateCoverDocument } from '../graphql/__generated__'

import type { UpdateCoverMutation} from '../graphql/__generated__'
import type { DestFormProps, FormRef } from './DestComponentEditor'
import type { CoverFormFields } from './CoverFormInput'
import CoverFormInput from './CoverFormInput'
import { findDestination } from '../utils/Destination'

export type CoverFormOptions = {
  onCustomCoverEdit?: (customCoverId: string) => void
  hasNonBlankCovers?: boolean
}

// NewCoverFormId to be used as customCoverId in form option's onCustomCoverEdit.
export const NewCoverFormId = "new"

const CoverForm = forwardRef<FormRef, DestFormProps>(({ options, destination, stock, onSave, onError }, formRef) => {

  const [validationError, setValidationError] = React.useState<string|undefined>(undefined)

  const [
    updateCover,
    { loading: saving, error },
  ] = useMutation<UpdateCoverMutation>(UpdateCoverDocument)

  const joinedError: string|undefined = error?.message || validationError

  async function submit(values: CoverFormFields) {
    if (!destination?.id) {
      throw new Error("No destination provided")
    }

    setValidationError(undefined)
    if (!values.coverId) {
      setValidationError("No cover selected")
      onError?.("No cover selected")
      return
    }

    updateCover({
      variables: {
        destinationId: destination.id,
        coverId: values.coverId,
      },
      onError: err => onError?.(err.message),
      onCompleted: ({ updateCover: _order }) => {
        const result = findDestination(_order, destination.id)
        result && onSave?.(result)
      },
    })
  }

  const methods = useForm<CoverFormFields>()
  const { handleSubmit, reset } = methods

  const { coverId } = destination ?? {}
  useEffect(() => reset({ coverId }), [reset, coverId])

  const errorHandler = useCallback(
    () => onError?.("Check validation errors on form"),
    [onError],
  )

  useImperativeHandle(formRef, () => ({
    saving,
    submit: handleSubmit(submit, errorHandler),
  }), [saving, errorHandler])

  return <div>
    <FormProvider {...methods}>
      <form onSubmit={ handleSubmit(submit, errorHandler) }>
        <div className="mx-auto md:mx-4 space-y-4">
          <CoverFormInput
            destinationId={ destination?.id }
            coverId={ destination?.coverId }
            onCustomCoverEdit={ options.onCustomCoverEdit }
            hasNonBlankCovers={ options.hasNonBlankCovers }
            stock={ stock }
          />

          { !!joinedError && (
            joinedError === 'No cover selected'
            ? <p className="p-2 m-4 bg-red-100 text-center text-lg"><span className="font-bold">No Cover Selected:</span> Please select a cover above to continue.</p>
            : <p className="p-2 m-4 bg-red-300">Error saving, please try again.</p>
          ) }
        </div>
      </form>
    </FormProvider>
  </div>
})

export default CoverForm
