import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import CircularProgress from '@mui/material/CircularProgress'

import { CustomCoversDocument, Maybe } from '../graphql/__generated__'
import type { StockFragment, CoverFragment } from '../graphql/__generated__'
import { isCustomCover } from '../utils/Covers'
import Button from './Button'
import { useAuthenticatedQuery, useAuthentication } from '../apollo-client'
import { NewCoverFormId } from './CoverForm'
import { formatPrice } from '../utils/Price'

export type CoverFormFields = {
  coverId: Maybe<string>
}

type CoverFormInputProps = {
  coverId?: string | null
  destinationId?: string
  stock?: StockFragment
  onCustomCoverEdit?: (customCoverId: string) => void
  hasNonBlankCovers?: boolean;
  onlyCustom?: boolean;
  hidePrices?: boolean;
}

export default function CoverFormInput({ coverId, destinationId, stock, onCustomCoverEdit, hasNonBlankCovers, onlyCustom, hidePrices }: CoverFormInputProps) {
  const { register } = useFormContext<CoverFormFields>();

  const book = stock?.books && stock?.books[0]
  const covers = (() => {
    if (onlyCustom) {
      return book?.covers.filter(cover => isCustomCover(cover.id))
    }
    return book?.covers.slice(0);
  })()

  const { isSudo } = useAuthentication()

  const { data, loading, error } = useAuthenticatedQuery(CustomCoversDocument, {
    notifyOnNetworkStatusChange: true,
  })

  if (!covers) {
    return <></>
  }

  covers.sort((a, b) => {
    return a.metadata.sortOrder - b.metadata.sortOrder
  })

  const customCovers = data?.customCovers
  let customCover: CoverFragment | undefined = undefined
  if (coverId && isCustomCover(coverId)){
    // Our stock covers all start with the 'cover-' prefix
    customCover = customCovers?.find((c: CoverFragment) => c.id === coverId)
  }

  type coverOption = {
    id: string
    image: string
    editable: boolean
    clonable: boolean
    soldOut: boolean
    cost?: number
    isFull: boolean
  }

  let options: Array<coverOption> = []

  // Add the custom covers
  if (data?.customCovers) {
    data.customCovers.forEach((cover: CoverFragment) => {
      let usedByDifferentDestination = false
      if (!destinationId && cover.usedByDestinations && cover.usedByDestinations.length > 0) {
        usedByDifferentDestination = true
      } else if (destinationId && cover.usedByDestinations) {
        usedByDifferentDestination = cover.usedByDestinations.length !== 1 || cover.usedByDestinations[0].id !== destinationId
      }

      if (cover.thumbnailImageURL) {
        options.push({
          id: cover.id,
          image: cover.thumbnailImageURL,
          editable: !usedByDifferentDestination || !!isSudo,
          soldOut: false,

          // Cloning is not actually supported yet
          clonable: false && usedByDifferentDestination,
          cost: cover.isFull ? 3000 : 2000,
          isFull: cover.isFull,
        })
      }
    })
  }

  // Add the stock covers
  covers.forEach((cover) => {
    if (!cover.images || !cover.images[0]) {
      return
    }

    options.push({
      id: cover.id,
      image: cover.images[0],
      editable: false,
      clonable: false,
      soldOut: !cover.allowSale,
      isFull: false,
    })
  })

  return <>
    <div className="grid grid-cols-4 gap-4 bg-gray-50 p-6 rounded">
      <div className="col-span-4 md:col-span-3">
        <p className="opacity-50">Use our design tool to create your own full-color custom cover featuring your photos and text.</p>
        <p className="opacity-50 text-sm mt-1 mb-2">Typical turnaround time for custom covers is 5-7 business days.</p>
        <div className="flex flex-col mt-2">
          <Button
            type="primary"
            onClick={ (e) => {
              e.preventDefault()
              onCustomCoverEdit?.(NewCoverFormId)
            } }
          >Create New Design{!hidePrices && (<> [{ formatPrice(2000) }]</>)}</Button>
          { customCover &&
            <Button
              className="mt-2"
              onClick={ (e) => {
                e.preventDefault()
                if (customCover) {
                  onCustomCoverEdit?.(customCover.id)
                }
              } }
            >Edit Existing Design</Button>
          }
        </div>
      </div>
      <div className="flex flex-col justify-center hidden md:block">
        <div
          className="h-full bg-cover bg-center bg-no-repeat bg-clip-padding rounded shadow"
          style={{ backgroundImage: `url(https://public.sendheirloom.com/CustomCovers.jpeg)` }}>
        </div>
      </div>
    </div>

    { loading && <div className="my-4 flex justify-center"><CircularProgress /></div> }
    { error && <p className="my-4 p-2 bg-red-100">Error loading custom covers.</p> }

    <ul className="grid grid-cols-2 md:grid-cols-4 gap-2">
      {
        options.map((option) => {
          if (!hasNonBlankCovers && (option.id !== 'cover-blank' && !isCustomCover(option.id))) {
            return
          }
          
          return <li key={ option.id }>
            <input
              {...register("coverId", {
                value: coverId,
              })}
              id={ option.id }
              type="radio" value={ option.id }
              disabled={ coverId !== option.id && option.soldOut }
              className="invisible absolute peer" />

            <label
              htmlFor={ option.id }
              data-cy={ option.id }
              className={`
                ${ option.isFull ? 'bg-contain' : 'bg-cover' }
                block w-full h-48 bg-center bg-no-repeat bg-clip-padding	cursor-pointer
                outline outline-solid outline-transparent outline-2 shadow rounded
                peer-checked:outline-pink-300 peer-checked:shadow-[0_0_10px_#fab1aa]
                flex flex-col justify-center align-center relative`}
              style={{ backgroundImage: `url("${ option.image }")` }}>
                { option.cost && !hidePrices && <div className="
                  absolute bottom-2 text-center w-full bold text-gray-900
                  ">
                    <span className="mb-2 px-2 py-1 bg-white/50 rounded">
                      +${ option.cost / 100 }
                    </span>
                  </div>
                }
                { (option.editable || option.clonable) &&
                  <Button
                    onClick={ (e) => {
                      e.preventDefault()

                      // TODO: there is currently no way in the UX to reuse an existing cover as a back cover
                      onCustomCoverEdit?.(option.id)
                    } }
                    className="opacity-70 hover:opacity-100 h-12 w-16 m-auto"
                  >{ option.editable ? "Edit" : "Clone" }</Button>
                }
                { option.soldOut &&
                  <div className="absolute top-0 left-0 w-full h-full bg-black/50 flex justify-center items-center rounded">
                    <div className="text-center">
                      <p className="text-white text-2xl">Sold Out</p>
                    </div>
                  </div>
                }
              </label>
          </li>
        })
      }
    </ul>
  </>
}
